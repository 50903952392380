.ProductCard {
}
.truncate-5 {
  display: -webkit-box !important;
  -webkit-line-clamp: 5 !important;
  line-clamp: 5 !important;
  box-orient: vertical !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /* color: #4f5154 !important; */
}
