.BaseNavbar {
}

.activeLink {
  font-weight: bold;
  color: black;
}
.notActiveLink {
  font-weight: lighter;
  color: grey;
}
