@media screen and (max-width: 220px) {
  button,
  label,p {
    font-size: 0.8rem !important;
  }
  h6,
  h5,
  h4,
  h3 {
    font-size: 1rem !important;
  }

  h2 {
    font-size: 1.1rem !important;
  }
  h1 {
    font-size: 1.2rem !important;
  }
}
